import type { AddressbaseFilter } from '../gql/schema';
import { TourFilterProperty } from '../models/BaseFilterInput';
import type { BaseTourDifficulty } from '../models/BaseTourDifficulty';
import { ImxPlatformModules } from '../models/ImxPlatformModules';
import type { TourSearchModel } from '../stores/searchStore';

export default (
  stateRef: MaybeRefOrGetter<TourSearchModel>,
  ignoreFieldsRef?: MaybeRefOrGetter<TourFilterProperty[]>
): Ref<AddressbaseFilter> => {
  const instanceConfigRef = useWhlInstanceConfig();

  const reverseDifficultyMappingRef: Ref<Record<BaseTourDifficulty, number[]>> =
    computed(() => {
      const instanceConfig = toValue(instanceConfigRef);

      const reverseDifficultyMapping: Record<BaseTourDifficulty, number[]> = {
        1: [],
        2: [],
        3: [],
      };

      const difficultyMapping = instanceConfig.tour?.difficultyMapping;
      if (difficultyMapping) {
        Object.entries(difficultyMapping).reduce((acc, [key, value]) => {
          if (!acc[value]) {
            acc[value] = [];
          }
          acc[value].push(Number(key));
          return acc;
        }, reverseDifficultyMapping);
      }

      return reverseDifficultyMapping;
    });

  return computed((): AddressbaseFilter => {
    const state: TourSearchModel = toValue(stateRef);
    const ignoreFields: TourFilterProperty[] = toValue(ignoreFieldsRef) ?? [];
    const reverseDifficultyMapping = toValue(reverseDifficultyMappingRef);

    const userFilter: AddressbaseFilter = {
      productlines: { oneOf: [ImxPlatformModules.TOUR] },
      moduleFilter: {
        tour: {
          categories: {
            oneOf: state.categories,
            allOf: state.criteria,
          },
          duration: {
            gte: state.duration?.at(0),
            lte: state.duration?.at(1),
          },
          length: {
            gte: state.length?.at(0),
            lte: state.length?.at(1),
          },
          difficulties: {
            oneOf: (state.difficulties as BaseTourDifficulty[]).flatMap(
              (difficulty) => reverseDifficultyMapping[difficulty] || []
            ),
          },
        },
      },
      group:
        state.locationType === 'AddressPoiGroup'
          ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
          : undefined,
      location:
        state.locationType === 'Location'
          ? { eq: state.locationId }
          : undefined,
      regions:
        state.locationType === 'Region'
          ? { oneOf: [state.locationId].filter((id) => isDefined(id)) }
          : undefined,
    };

    if (
      (ignoreFields as TourFilterProperty[]).indexOf(
        TourFilterProperty.CATEGORY
      ) !== -1
    ) {
      delete userFilter.moduleFilter?.tour?.categories;
    }
    if (
      (ignoreFields as TourFilterProperty[]).indexOf(
        TourFilterProperty.ATTRIBUTE
      ) !== -1
    ) {
      delete userFilter.moduleFilter?.tour?.attributes;
    }

    return userFilter;
  });
};
